<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col lg="12" md="12">
          <v-card>
            <v-card-title>
              <v-icon
                @click="pushRouteToView('banners')"
                class="mr-4"
                style="cursor: pointer"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon>mdi-account-box</v-icon>
              Banner
            </v-card-title>
          </v-card>
        </v-col>
        <v-col lg="12" md="12">
          <v-card>
            <v-card-title>
              <v-row align="center" justify="center" class="text-center">
                <v-col v-if="image !== ''" lg="12" md="12">
                  <img :src="image" alt="imagem" />
                </v-col>
                <v-col md="3" lg="3" sm="12">
                  <v-text-field label="Nome" v-model="name" outlined />
                </v-col>
                <v-col md="3" lg="3" sm="12">
                  <v-text-field
                    v-model="image"
                    type="file"
                    outlined
                    @change="onFileChange($event)"
                    accept="image/*"
                  />
                </v-col>

                <v-col md="12" lg="12">
                  <v-row justify="center" align="center">
                    <v-col class="items-center" md="6">
                      <v-alert
                        style="align-items: center"
                        text
                        type="success"
                        v-if="image !== ''"
                      >
                        Upload de imagem com sucesso!
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BannerCreate",

  data: () => ({
    image: "",
    name: ""
  }),

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },
    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.convertFileToBase64(selectedImage);
    },
    convertFileToBase64(fileObject) {
      const reader = new FileReader();

      reader.onload = e => {
        this.image = e.target.result;
        console.log(e);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(fileObject);
    }
  }
};
</script>
